import { useCallback, useRef, useState } from 'react';
import { useConfiguration } from '../context/configurationContext';
import CryptoJS from 'crypto-js';

export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr?.length > 0 ?? false);
};

export const getDefaultLocale = () => {
  const hasEnglishLocale = typeof window !== 'undefined' && location?.pathname?.includes('/en');

  if (hasEnglishLocale && typeof window !== 'undefined') {
    return 'en';
  }

  const defaultLocale = typeof window !== 'undefined' && window.localStorage.getItem('locale');

  return defaultLocale || 'de';
};

export const isLocaleGreek = () => {
  const config = useConfiguration();
  const locale = config.localization.locale;
  return locale === 'de';
};

export const onSendEmail = (body = null) => {
  const isValidBody = typeof body === 'string';

  if (typeof window !== 'undefined') {
    const email = 'info@vivianlab.com';
    const encodedBody = isValidBody && encodeURIComponent(body);
    const messageWithBody = isValidBody
      ? `mailto:${email}?subject=Contact VivianLab&body=${encodedBody}`
      : `mailto:${email}`;
    window.location.href = messageWithBody;
  }
};

// Encryption function
export const encrypt = (plaintext, secretKey) => {
  const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
  return ciphertext;
};

// Decryption function
export const decrypt = (ciphertext, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const isImageLink = link => {
  // Supported image file extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'heic', 'heif'];

  // Get the file extension from the link
  const extension = link
    .split('.')
    .pop()
    .toLowerCase();

  // Check if the extension matches any of the image extensions
  return imageExtensions.includes(extension);
};

export const appendAverageReviews = reviews => {
  const MIN_RATING = 1;
  const MAX_RATING = 5;

  const filteredReviews =
    Array.isArray(reviews) &&
    reviews.filter(r => r?.attributes?.rating !== null && r?.attributes?.state === 'public');

  const totalReviews = filteredReviews?.length || 0;

  if (!totalReviews) {
    return null;
  }

  const ratings = filteredReviews.map(review => review?.attributes?.rating);
  const validRatings = ratings.filter(r => r >= MIN_RATING && r <= MAX_RATING);

  if (!validRatings.length) {
    return null;
  }

  const starSum = validRatings.reduce((partialSum, rating) => partialSum + rating, 0);
  const averageRating = starSum / validRatings.length;

  return averageRating && averageRating.toFixed(1);
};

export const getPreferredLanguage = user => {
  return user?.attributes?.profile?.protectedData?.preferredLang || 'gr';
};

export const sortArrayAlphabetically = array => {
  // Clone the original array to avoid modifying the original data
  const sortedArray = [...array];

  // Sort the array alphabetically by the "value" property
  sortedArray.sort((a, b) => a.label.localeCompare(b.label));

  return sortedArray;
};

export const sortGreekLabels = data => {
  return data.sort((a, b) => new Intl.Collator('el').compare(a.label, b.label));
};

export const sortGreekCountriesLabels = data => {
  return data.sort((a, b) => new Intl.Collator('el').compare(a.name, b.name));
};

export const removeOfflineOption = services => {
  // Map through each service in the array
  return services.map(service => {
    // Filter out the 'offline' option from the bookingOptions array
    const updatedBookingOptions = service.bookingOptions.filter(option => option !== 'Offline');

    // Return the modified service object with the updated bookingOptions
    return {
      ...service,
      bookingOptions: updatedBookingOptions,
    };
  });
};

export const calculateSubscriptionPrice = ({ price: priceStr, percentageOff, currency }) => {
  // Extract the numerical part of the price from the string
  const price = !!priceStr && parseFloat(priceStr.replace(/[^\d.-]/g, ''));

  // Calculate the discount amount
  const discount = (price * percentageOff) / 100;

  // Calculate the final price
  const finalPrice = price - discount;
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(finalPrice);
  return { finalPrice: formattedPrice, priceAmount: finalPrice };
};
