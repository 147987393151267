import { fetchBlogs, fetchTagsFilter } from '../../util/api';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const SEARCH_BLOGS_REQUEST = 'app/OurMenopauseJourneyPage/SEARCH_BLOGS_REQUEST';
export const SEARCH_BLOGS_SUCCESS = 'app/OurMenopauseJourneyPage/SEARCH_BLOGS_SUCCESS';
export const SEARCH_BLOGS_ERROR = 'app/OurMenopauseJourneyPage/SEARCH_BLOGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  blogsData: [],
  searchBlogsError: null,
};

const blogsPageReducers = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_BLOGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        blogsData: payload?.data || [],
      };
    case SEARCH_BLOGS_ERROR:
      return { ...state, searchInProgress: false, searchBlogsError: true };
    default:
      return state;
  }
};

export default blogsPageReducers;

// ================ Selectors ================ //

export const fetchBlogsSelector = state => state.BlogPage.blogsData?.blogs;

// ================ Action creators ================ //

export const searchBlogsRequest = () => ({
  type: SEARCH_BLOGS_REQUEST,
});

export const searchBlogsSuccess = response => ({
  type: SEARCH_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchBlogsError = () => ({
  type: SEARCH_BLOGS_ERROR,
});

export const fetchAllBlogs = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());
  const defaultLocale = params?.locale == 'de' ? 'el-GR' : 'en';

  try {
    const [blogsResponse, tagsResponse] = await Promise.all([
      fetchBlogs({ ...params, defaultLocale }),
      fetchTagsFilter(),
    ]);
    dispatch(searchBlogsSuccess({ blogs: blogsResponse?.blogs, filters: tagsResponse?.tags }));
  } catch (error) {
    dispatch(searchBlogsError());
  }
};

export const loadData = (params, search, config) => {
  const locale = config?.localization?.locale || 'de';
  const parsedSearch = search && parse(search);
  return fetchAllBlogs({ tags: parsedSearch?.tags, locale });
};
