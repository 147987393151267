import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { createStripeSubscription, getSubscriptionProductPrice } from '../../util/api';
import { stripeCustomer } from '../PaymentMethodsPage/PaymentMethodsPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/SubscriptionPage/SET_INITIAL_VALUES';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchProductPriceInProgress: false,
  productPrices: [],
  fetchProductPriceError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST:
      return { ...state, fetchProductPriceInProgress: true, fetchProductPriceError: null };
    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        productPrices: payload,
        fetchProductPriceInProgress: false,
        fetchProductPriceError: null,
      };
    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR:
      return {
        ...state,
        fetchProductPriceInProgress: false,
        fetchProductPriceError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //
export const subscriptionSelectors = state => {
  const {
    fetchProductPriceInProgress,
    productPrices,
    fetchProductPriceError,
  } = state.SubscriptionPage;
  return {
    fetchProductPriceInProgress,
    productPrices,
    fetchProductPriceError,
  };
};

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getSubscriptionProductPriceRequest = () => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST,
});
export const getSubscriptionProductPriceSuccess = data => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS,
  payload: data,
});
export const getSubscriptionProductPriceError = error => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const getSubscriptionPrices = () => async (dispatch, getState, sdk) => {
  dispatch(getSubscriptionProductPriceRequest());
  try {
    const response = await getSubscriptionProductPrice();
    dispatch(getSubscriptionProductPriceSuccess(response));
  } catch (e) {
    dispatch(getSubscriptionProductPriceError(storableError(e)));
  }
};

export const createSubscription = params => async (dispatch, getState, sdk) => {
  return await createStripeSubscription(params);
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = await dispatch(fetchCurrentUser());
      dispatch(setInitialValues());
      dispatch(getSubscriptionPrices());
      dispatch(stripeCustomer());
      resolve(currentUser);
    } catch (error) {
      reject(error);
    }
  });
};
