import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { selectCurrentUser } from '../../ducks/user.duck';
import { useSelector } from 'react-redux';

import css from './OrderBreakdown.module.css';
import { getWalletBalance } from '../../util/dataExtractors';

const LineItemUserWalletBalance = props => {
  const currentUser = useSelector(selectCurrentUser);
  const walletBalance = getWalletBalance(currentUser);

  if (!walletBalance) {
    return <></>;
  }

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.voucherBalance" />
      </span>
      <span className={css.itemValue}>€{Number(walletBalance).toFixed(2)}</span>
    </div>
  );
};

export default LineItemUserWalletBalance;
